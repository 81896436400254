<template>
  <BaseCard
    :caption-visible="false"
    :footer-visible="false"
    actions-visible
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">ofertas</span>
    </template>
    <template #actions>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_OFFERS,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        :to="{ name: 'createOffer' }"
      >
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          + Añadir oferta
        </b-button>
      </b-link>
    </template>
    <OffersTable />
  </BaseCard>
</template>

<script>
import OffersTable from '@/components/offers/table/OffersTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: {
    BaseCard,
    OffersTable,
  },
}
</script>

<style scoped>
</style>
